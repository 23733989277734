import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Spiritual .css";
import Spiritulbanner from "../../../assets/PagesImages/HSS.png";

const Spiritual = () => {
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "50vh",
  };
  const slideImages = [
    {
      url: Spiritulbanner,
      caption: "Hawaii Spiritual Center",
    },
    // {
    //   url: "https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80",
    //   caption: "Hawaii Spiritual Center",
    // },
    // {
    //   url: "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    //   caption: "Hawaii Spiritual Center",
    // },
  ];
  return (
    <>
      <Navbar backgroundColor="#000" />
      <div className="slide-container Slidertop">
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              >
                <span className="page-Name">{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
      <div className="SAIL">
        <div className="SAIL-Prime">
          <div className="Picture-Div">
            <div className="Picture"></div>
            <div className="Details">
              <h2 className="Details-heading">
                Hawaii Spiritual Center & Center for
                <br /> Inter-Faith Relationships
              </h2>
              <p className="pedd-text">
                Hawaii Spiritual Center (HSC) is a C-Corporation established in
                January 2020 in Hawaii to create a wellness and health center
                for its members. HSC is transitioning to a non-profit
                organization called Ko'olauloa Foundation, registered in April
                2020. HSC oversees the operations of affiliates Buddha's Light
                International Agriculture Inc. and Universal Life Science
                Research Foundation, and its mission is to promote wellness and
                healing for the body, mind, spirit, and soul of its members. The
                organization, led by a dedicated team, is working towards its
                vision and mission.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div className="detail-Image"></div>
              <div className="detail-Image-Two"></div>
            </div>
            <div className="detail-Image-Three"></div>
            {/* 
            <div className="Details">
              <p className="pedd-text">
                Creating a new platform for progressive education in renewable
                technologies, media production, health and wellness, and
                cultural heritage, the Academy will provide much-needed
                facilities to foster the new innovations that will help solve
                some of the great economic and environmental challenges of
                today. The Academy will be established at the Ko’olauloa
                Foundation offering a specialized set of courses and experiences
                with local indigenous wisdom blended into the curriculum.
              </p>
            </div> */}
          </div>
          <div className="Details">
            <p className="pedd-text">
              The Hawai'i Spiritual Center provides a space in Oahu's Koolauloa
              region where people
              <br /> of diverse backgrounds can join together for shared
              exploration and growth,
              <br /> healing, celebration, and renewal.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Spiritual;
