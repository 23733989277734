import React from "react";
import "./Affiliates.css";

const Affiliates = () => {
  return (
    <div className="Aboutus">
      <h1 className="About-Heading">Our Present and Future Affiliates</h1>
      <div>
        <div className="Tree-Pillers">
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">Farmaing Research & Farm Hub</h3>
            </div>
            <a href="https://www.kahumana.org/">- Kahumana</a>
            <br />
            <a href="https://www.maoorganicfarms.org/">- MAO Farms</a>
            <br />
            <a href="https://www.byuh.edu/about">- BYUH Farm Research</a>
            <br />
            <a href="https://manoa.hawaii.edu/about/">- UH Farm Reseach</a>
            <br />
            <a>- USDA</a>
            <br />
            <a>- Kela</a>
            <br />
            <a>- PONO Experience</a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">
                Hawaii-Oceania Education & Cultural Center
              </h3>
            </div>
            <a href="https://www.oahuex.com/">- Oahu Experience</a> <br />
            <a>- VAA Beyond</a> <br />
            <a href="https://www.lahuiokoolauloa.org/">
              - Lahui o' Koolauloa
            </a>{" "}
            <br />
            <a>- UH Farm Reseach</a> <br />
            <a>- PONO Experience</a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">
                Scientific Academy For Innovation Learning & Leadership
              </h3>
            </div>
            <a>- OLA Vibes</a> <br />
            <a>- Lahui o' Koolauloa</a> <br />
            <a>- VAA Beyond</a> <br />
            <a>- PONO Experience</a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">Hawaii Spiritual Center</h3>
            </div>
            <a href="https://www.justbreatheretreat.com/">- Just Breathe</a>
            <br />
            <a>- Kela</a> <br />
            <a>- OLA Vibes</a>
          </div>
        </div>
        <div className="Tree-Pillers">
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">Hawaii Integrative Health Center</h3>
            </div>
            <a href="https://www.justbreatheretreat.com/">- Just Breathe</a>
            <br />
            <a>- OLA Vibes</a> <br />
            <a>- PONO Experience</a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">
                Hawaii Elite Athletic traning Center & Others
              </h3>
            </div>
            <a>- Trilogy</a> <br />
            <a>- K Team Jiu Jitsu</a> <br />
            <a>- HF-USAW</a> <br />
            <a>- Lahui o' Koolauloa</a> <br />
            <a href="https://www.facebook.com/profile.php?id=61550114372584">
              - VAA Beyond
            </a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">
                KF
                <br />
                Construction
              </h3>
            </div>
            <a href="https://olavibes.org/">- OLA Vibes</a>
          </div>
          <div className="Branch">
            <div>
              <h3 className="Tree-Branch">Retreat & Community Center</h3>
            </div>
            <a href="https://www.justbreatheretreat.com/">- Just Breathe</a>
            <br />
            <a>- PONO Experience</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affiliates;
