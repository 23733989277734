import React from "react";
import "./Table.css";

const Table = () => {
  return (
    <>
      <section>
        <h2>• LAND AT GLANCE •</h2>
        <h2 style={{ fontSize: "45px" }}>KALUANUI ACRES</h2>
        <div
          style={{
            border: " solid 1px rgb(196, 194, 194)",
            width: "79.5%",
            textAlign: "center",
          }}
        >
          <h2>53-XXX Puhuli Street, Hauula, HI 96717</h2>
        </div>
        <div class="tbl-header">
          <table cellpadding="0" cellspacing="0" border="0">
            <thead>
              <th>Lot#</th>
              <th>Parcel ID</th>
              <th>
                Acreage <br />
                (Big+Small Lots)
              </th>
              <th>Total Acres</th>
              <th>Prop. Class</th>
              <th>Sale Price</th>
              <th>Owner</th>
            </thead>
          </table>
        </div>
        <div class="tbl-content">
          <table cellpadding="0" cellspacing="0" border="0">
            <tbody>
              <td>6A</td>
              <td>530090880001</td>
              <td>4.1</td>
              <td rowSpan={"2"}> 4.444</td>
              <td>Ag-2</td>
              <td>$1,100,000</td>
              <td>BUDDHA'S LIGHT INTL AGR INC</td>
              <tr>
                <td>6A-1</td>
                <td>530090880002</td>
                <td>0.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6B</td>
                <td>530090890001</td>
                <td>4.227</td>
                <td rowSpan={"2"}>4.571</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6B-1</td>
                <td>530090890002</td>
                <td>0.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6C</td>
                <td>530090900001</td>
                <td>5</td>
                <td rowSpan={"2"}>5.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6C-1</td>
                <td>530090900002</td>
                <td>0.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6D</td>
                <td>530090910001</td>
                <td>5</td>
                <td rowSpan={"2"}>5.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6D-1</td>
                <td>530090910002</td>
                <td>0.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6E</td>
                <td>530090920001</td>
                <td>5</td>
                <td rowSpan={"2"}>5.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
              <tr>
                <td>6E-1</td>
                <td>530090920002</td>
                <td>0.344</td>
                <td>Ag-2</td>
                <td>$1,100,000</td>
                <td>BUDDHA'S LIGHT INTL AGR INC</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
      <section>
        <h2>• LAND AT GLANCE •</h2>
        <h2 style={{ fontSize: "45px" }}>KALUANUI ACRES</h2>
        <div class="tbl-header">
          <table cellpadding="0" cellspacing="0" border="0">
            <thead>
              <th>Map Location</th>
              <th>Distance from Lot 6 (miles)</th>
              <th>Travel Time (mins)</th>
            </thead>
          </table>
        </div>
        <div class="tbl-content">
          <table cellpadding="0" cellspacing="0" border="0">
            <tbody>
              <tr>
                <td>Lāʻie Shopping Center</td>
                <td>5</td>
                <td>9</td>
              </tr>
              <tr>
                <td>Polynesian Cultural Center</td>
                <td>4</td>
                <td>15</td>
              </tr>
              <tr>
                <td>Turtle Bay Resort</td>
                <td>12</td>
                <td>25</td>
              </tr>
              <tr>
                <td>Shark’s Cove</td>
                <td>17</td>
                <td>30</td>
              </tr>
              <tr>
                <td>Waimea Bay</td>
                <td>17</td>
                <td>30</td>
              </tr>
              <tr>
                <td>Windward Mall</td>
                <td>18</td>
                <td>31</td>
              </tr>
              <tr>
                <td>Haleiwa</td>
                <td>22</td>
                <td>41</td>
              </tr>
              <tr>
                <td>Chinatown</td>
                <td>27</td>
                <td>44</td>
              </tr>
              <tr>
                <td>Daniel K. Inouye International Airport</td>
                <td>29</td>
                <td>46</td>
              </tr>
              <tr>
                <td>Waikiki</td>
                <td>31</td>
                <td>51</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
};

export default Table;
