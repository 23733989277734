import React from "react";
import "./Friends.css";

const Friends = () => {
  return (
    <div className="Friends">
      <h1 className="Friend-Heading">
        Mahalo nui loa for supporting
        <br /> Ko'olauloa Foundation
      </h1>
      <div className="Friends-Section">
        <div className="Friends-Div">
          <p className="Friend">Friends of KF</p>
          <a
            href="https://buy.stripe.com/3cs00NaE59iV4tq5kl"
            target="blank"
            className="Firends-Button"
          >
            Donate
          </a>
        </div>
        <div className="Friends-Div">
          <p className="Friend">Memberships</p>
          <a
            href="https://donorbox.org/koolauloa-foundation-membership"
            target="blank"
            className="Firends-Button"
          >
            Donate
          </a>
        </div>
        <div className="Friends-Div">
          <p className="Friend">Payments to KF</p>
          <a
            href="https://buy.stripe.com/7sI9Bn7rTamZ7FC000"
            target="blank"
            className="Firends-Button"
          >
            Pay Now
          </a>
        </div>
      </div>
    </div>
  );
};

export default Friends;
