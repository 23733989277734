import React, { useState } from "react";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "./Card.css";
import { importAllImages } from "./CardsImage";

const Card = () => {
  // Manage states dynamically
  const [lightboxOpen, setLightboxOpen] = useState({});

  const openLightbox = (category) => {
    setLightboxOpen({ ...lightboxOpen, [category]: true });
  };

  const closeLightbox = (category) => {
    setLightboxOpen({ ...lightboxOpen, [category]: false });
  };

  // Import images dynamically
  const images = importAllImages(
    require.context("../../../assets/ProgramCenter")
  );

  console.log(images);

  // Define image categories and their corresponding images
  const imageData = [
    { category: "oahuYurts", name: "Oahu Yurts", keyword: "Oahu" },
    { category: "hawaiiYurts", name: "Hawaii Yurts", keyword: "Hawaii" },
    { category: "mauiYurts", name: "Maui Yurts", keyword: "Maui" },
    { category: "lanaiYurts", name: "Lanai Yurts", keyword: "Lanai" },
    { category: "molokaiCabin", name: "Molokai Cabin", keyword: "Molokai" },
    {
      category: "kahoolaweCabin",
      name: "Kahoolawe Cabin",
      keyword: "Kahoolawe",
    },
    { category: "hale", name: "Multi Purpose Center (HALE)", keyword: "Hale" },
    { category: "programArea", name: "Program Area", keyword: "ProgramArea" },
  ];

  return (
    <div className="Gallery-Component">
      <h1 className="Gallery-Heading">Program Center</h1>
      <div className="Cards-div">
        {imageData.map((data, index) => (
          <div
            key={index}
            className="Card"
            onClick={() => openLightbox(data.category)}
          >
            <div className="Image"></div>
            <h3 className="Cards-Heading">{data.name}</h3>
            <Lightbox
              open={lightboxOpen[data.category]}
              close={() => closeLightbox(data.category)}
              slides={Object.values(images)
                .filter((image) => image.includes(data.keyword))
                .map((src, imgIndex) => ({
                  src: src,
                  caption: `${data.name} - Image ${imgIndex + 1}`,
                }))}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
