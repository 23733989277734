import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Athletic .css";
import AthleticBanner from "../../../assets/PagesImages/HEAT1.png";
import AthleticBanner1 from "../../../assets/PagesImages/HEAT2.png";
import AthleticBanner2 from "../../../assets/PagesImages/HEAT3.png";

const Athletic = () => {
  // const spanStyle = {
  //   padding: "20px",
  //   background: "#efefef",
  //   color: "#000000",
  // };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "50vh",
  };
  const slideImages = [
    {
      url: AthleticBanner,
      caption: "Hawaii Elite Athletic Training Center",
    },
    {
      url: AthleticBanner1,
      caption: "Hawaii Elite Athletic Training Center",
    },
    {
      url: AthleticBanner2,
      caption: "Hawaii Elite Athletic Training Center",
    },
  ];
  return (
    <>
      <Navbar />
      <div className="slide-container Slidertop">
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              >
                <span className="page-Name">{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
      <div className="SAIL">
        <div className="SAIL-Prime">
          <div className="Picture-Div">
            <div className="Picture"></div>
            <div className="Details">
              <h2 className="Details-heading">
                Hawaii Elite Athletic Training Center
              </h2>
              <p className="pedd-text">
                Hawaii Elite Athletic Training Center (HEAT) is dedicated to
                providing top-notch fitness programs and training services.
                Established in January 2020, HEAT has evolved into a premier
                destination for individuals seeking excellence in their fitness
                journey. As part of the Ko'olauloa Foundation, registered in
                April 2020, HEAT is committed to fostering physical well-being
                and personal growth. Our team, comprised of experienced coaches
                and fitness enthusiasts, oversees a range of programs, including
                strength training, cardio workouts, and specialized fitness
                classes. We strive to create a community where members can
                achieve their fitness goals, promoting a holistic approach to
                health that encompasses the body, mind, and spirit. Join us at
                HEAT and embark on a transformative fitness experience led by a
                passionate and dedicated team.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div className="detail-Image"></div>
              <div className="detail-Image-Two"></div>
            </div>
            <div className="detail-Image-Three"></div>
          </div>
          <div className="Details">
            <p className="pedd-text">
              <span>
                <a
                  className="About-Heading"
                  target="blank"
                  href="https://eastmanpublic.s3.amazonaws.com/kfoundation/WhatsApp+Image+2024-02-15+at+2.33.40+PM.pdf"
                >
                  HEAT
                </a>
              </span>
              is a pillar of KF which promotes each sport, under a collective of
              similarart such as grappling, where educators or coach(s) can
              knowledge transfer,
              <br />
              train and guide athletes towards personal growth and development.
              <br />
              Each sport or sport will be managed by a director or coach level
              to
              <br /> run programs for training, competitions, tournaments, and
              camps at
              <br />
              KF and other approved facilities.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Athletic;
