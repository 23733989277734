import React, { useEffect } from "react";
import "./AboutUs.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import AboutUsHead from "../../components/AboutUs/Head/AboutUsHead";
import Team from "../../components/AboutUs/Team/Team";
import Affiliates from "../../components/AboutUs/Affiliates/Affiliates";

const AboutUs = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Navbar />
      <div className="AboutUs">
        <div className="AboutUs-Banner">
          <h1 className="AboutUs-heading">About Us</h1>
        </div>
      </div>
      <AboutUsHead />
      <Team />
      <Affiliates />
      <Footer />
    </>
  );
};

export default AboutUs;
