import React, { useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import "./Shop.css";
import Footer from "../../components/Footer/Footer";
import Map from "../../components/Map/Map";
import Table from "../../components/Table/Table";

const Shop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  // const [showLot6Details, setShowLot6Details] = useState(false);
  return (
    <div>
      <Navbar backgroundColor="#000" />
      <Map />
      <Table />
      <Footer />
    </div>
  );
};

export default Shop;
