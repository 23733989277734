import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";

const Community = () => {
  return (
    <>
      <Navbar />
      <div className="AboutUs">
        <div className="AboutUs-Banner">
          <h1 className="AboutUs-heading">Community Center</h1>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Community;
