import React, { useState } from "react";
import "./Gallery.css";
import Lightbox from "yet-another-react-lightbox";
import Video from "yet-another-react-lightbox/plugins/video";
import "yet-another-react-lightbox/styles.css";
import { importAllImages } from "./GalleryImages";

const Gallery = () => {
  const [lightboxOpen, setLightboxOpen] = useState({
    facility: false,
    programs: false,
    videos: false,
  });

  const openLightbox = (lightbox) => {
    setLightboxOpen({ ...lightboxOpen, [lightbox]: true });
  };

  const closeLightbox = (lightbox) => {
    setLightboxOpen({ ...lightboxOpen, [lightbox]: false });
  };

  const facilityImages = importAllImages(
    require.context(
      "../../../assets/Gallery/Facility",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  const programImages = importAllImages(
    require.context(
      "../../../assets/Gallery/Program",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  return (
    <div className="Gallery-Component" id="Gallery-Component">
      <h1 className="Gallery-Heading">Gallery</h1>
      <div className="Gallery">
        <div className="gallery-Prime">
          <div className="Gallery-Card">
            <div className="Programs-pictures">
              Programs
              <div className="Addition">Programs</div>
            </div>
            <p className="Detaing-Text">
              Immerse yourself in visual tales of ancient Programs that shaped
              the cultural landscape.
            </p>
            <button
              onClick={() => openLightbox("programs")}
              className="gallery-Button"
            >
              Learn More
            </button>
            <Lightbox
              open={lightboxOpen.programs}
              close={() => closeLightbox("programs")}
              slides={Object.values(programImages).map((image, index) => ({
                src: image,
                key: index,
              }))}
            />
          </div>
        </div>
        <div className="gallery-Prime">
          <div className="Gallery-Card">
            <div className="Facility-pictures">
              Facility
              <div className="Addition">Facility</div>
            </div>
            <p className="Detaing-Text">
              Explore our collection of facility pictures showcasing the
              Facility Programs.
            </p>
            <button
              onClick={() => openLightbox("facility")}
              className="gallery-Button"
            >
              Learn More
            </button>
            <Lightbox
              open={lightboxOpen.facility}
              close={() => closeLightbox("facility")}
              slides={Object.values(facilityImages).map((image, index) => ({
                src: image,
                key: index,
              }))}
            />
          </div>
        </div>
        <div className="gallery-Prime">
          <div className="Gallery-Card">
            <div className="Videos">
              Video
              <div className="Addition">Video</div>
            </div>
            <p className="Detaing-Text">
              Witness the grandeur a powerful civilization through our
              captivating collection of videos.
            </p>
            <button
              onClick={() => openLightbox("videos")}
              className="gallery-Button"
            >
              Learn More
            </button>
            <Lightbox
              open={lightboxOpen.videos}
              close={() => closeLightbox("videos")}
              plugins={[Video]}
              slides={[
                {
                  type: "video",
                  width: 1280,
                  height: 720,
                  sources: [
                    {
                      src: "https://eastmanpublic.s3.amazonaws.com/kfoundation/Video1.mp4",
                      type: "video/mp4",
                    },
                  ],
                },
                {
                  type: "video",
                  width: 1280,
                  height: 720,
                  sources: [
                    {
                      src: "https://eastmanpublic.s3.amazonaws.com/kfoundation/Video2.mp4",
                      type: "video/mp4",
                    },
                  ],
                },
                {
                  type: "video",
                  width: 1280,
                  height: 720,
                  sources: [
                    {
                      src: "https://eastmanpublic.s3.amazonaws.com/kfoundation/Video3.mp4",
                      type: "video/mp4",
                    },
                  ],
                },
                {
                  type: "video",
                  width: 1280,
                  height: 720,
                  sources: [
                    {
                      src: "https://eastmanpublic.s3.amazonaws.com/kfoundation/Video4.mp4",
                      type: "video/mp4",
                    },
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
