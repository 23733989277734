import React, { useState } from "react";
import "./SlideShow.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import images from "./SlideShowImages";

const SlideShow = () => {
  const [autoPlay, setAutoPlay] = useState(false);
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </button>
    ),
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "60vh",
  };
  const slideImages = [
    {
      url: images["SlideShow1.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">We Serve Our</h1>
          <div className="SlideShow-Text">
            <div className="Sections">
              <p className="SlideShow-Details">
                Ko’olauloa Foundation is a registered nonprofit in the State of
                Hawaii focusing on serving our local and global communities with
                great respect to the Hawaiian culture and education along with
                promoting farming, wellness, healing, health and community
                service.”
              </p>
            </div>
            <div className="Sections">
              <h1 className="Heading">Community</h1>
            </div>
          </div>
        </div>
      ),
    },
    {
      url: images["SlideShow2.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Healing</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow3.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Activity</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow4.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Nurture</h1>
          <div className="SlideShow-Text">
            <div className="Sections"></div>
            <div className="Sections">
              <h1 className="Heading">Togetherness</h1>
            </div>
          </div>
        </div>
      ),
    },
    {
      url: images["SlideShow5.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Culture</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow6.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Explore</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow7.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Children Are Our Future</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow8.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">The Dream Of Ko`olauloa Foundation</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow9.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Aloha</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow10.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Pono</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow11.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">A Hui Ho!</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow12.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Mind, Body, Spirit, Soul</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow13.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Kaluanui Ahupua's</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow14.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Community & Volunteer</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow15.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Reverence</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow16.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Ohana</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow17.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Heartful Retreat</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow18.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Community</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow19.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Wa's</h1>
        </div>
      ),
    },
    {
      url: images["SlideShow20.png"],
      caption: (
        <div className="SideShow">
          <h1 className="Heading">Natural</h1>
        </div>
      ),
    },
  ];

  return (
    <div className="slide-container">
      <Slide
        autoplay={autoPlay}
        prevArrow={properties.prevArrow}
        nextArrow={properties.nextArrow}
      >
        {slideImages.map((slideImage, index) => (
          <div key={index}>
            <div
              style={{
                ...divStyle,
                backgroundImage: `url(${slideImage.url})`,
              }}
            >
              <span>{slideImage.caption}</span>
            </div>
          </div>
        ))}
      </Slide>
    </div>
  );
};

export default SlideShow;
