import React from "react";
import "./AboutUsHead.css";
import About from "../../../assets/Aboutus/AboutUs.png";
import About2 from "../../../assets/Aboutus/aboutus2.png";

const AboutUsHead = () => {
  return (
    <div className="Aboutus1">
      <div className="Aboutus-Prime">
        <div className="About-Pic">
          <img alt="AboutImage" className="About-Picture" src={About} />
          <img alt="AboutImage" className="About-Picture2" src={About2} />
        </div>
        <div className="About">
          <p className="anoutUs-details1">
            <span>
              <a
                className="About-Heading"
                target="blank"
                href="https://docs.google.com/presentation/d/16hB35NlxLW8iaHzxc4MmmACEyYVPbgSh/edit?rtpof=true"
              >
                Ko’olauloa Foundation
              </a>
            </span>
            is a registered non-profit in the State of Hawaii focusing on
            serving our local and global communities with great respect to the
            Hawaiian culture and education along with promoting farming,
            wellness, healing, health and community service.
          </p>
          <p className="anoutUs-details">
            KF is the operating and managing entity over the affiliates of
            Buddha’s Light International Agriculture Inc. and Universal Life
            Science Research Foundation.
          </p>
          <p className="anoutUs-details">
            KF sets its mission on building a centre aimed at serving humanity
            in the new century. KF’s focus will lean on wellness, healing and
            health of and for the body, the mind, the spirit and the soul of its
            members. Our organization, consisting of able-educated team members,
            is paving the road to achieving the goals we’ve set in the embodied
            vision and mission.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHead;
