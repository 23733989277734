import React from "react";
import "./Pillers.css";
import images from "./PillersImages";
import { Link } from "react-router-dom";

const Pillers = () => {
  return (
    <div className="Pillers">
      <h1 className="Pillers-Heading">Pillars of Ko`olauloa Foundation</h1>
      <div className="Pillers-Section">
        <Link to="/Farming Research & Farm Hub" className="Piller">
          <img className="Piller-Image" src={images["Farming.png"]} />
          <p>
            Farming Research
            <br /> & Farm Hub
          </p>
        </Link>
        <Link
          to="/Hawaii-Oceania Education & Cultural Center"
          className="Piller"
        >
          <img className="Piller-Image" src={images["Cultural.png"]} />
          <p>
            Hawaii-Oceania Education <br /> & Cultural Center
          </p>
        </Link>
        <Link
          to="/Scientific Academy For Innovative Learning & Leadership"
          className="Piller"
        >
          <img className="Piller-Image" src={images["Scientific.png"]} />
          <p>
            Scientific Academy For Innovative
            <br /> Learning & Leadership
          </p>
        </Link>
        <Link to="/Hawaii Elite Athletic Training Center" className="Piller">
          <img className="Piller-Image" src={images["Athletic.png"]} />
          <p>
            Hawaii Elite Athletic
            <br /> Training Center
          </p>
        </Link>
        <Link to="/Integrative Health & Healing Center" className="Piller">
          <img className="Piller-Image" src={images["regeneration.png"]} />
          <p>
            Integrative Health &<br /> Healing Center
          </p>
        </Link>
        <Link
          to="/Hawaii Spiritual Center & Center for Inter-Faith Relationships"
          className="Piller"
        >
          <img className="Piller-Image" src={images["Spiritual.png"]} />
          <p>
            Hawaii Spiritual Center & Center
            <br /> for Inter-Faith Relationships
          </p>
        </Link>
        <Link to="/KF Construction" className="Piller">
          <img className="Piller-Image" src={images["Construction.png"]} />
          <p>
            KF
            <br /> Construction
          </p>
        </Link>
      </div>
    </div>
  );
};

export default Pillers;
