import React, { useEffect } from "react";
import "./Home.css";
import Navbar from "../../components/Navbar/Navbar";
import SlideShow from "../../components/Home/SlideShow/SlideShow";
import Pillers from "../../components/Home/Pillers/Pillers";
import SubPiller from "../../components/Home/SubPiller/SubPiller";
import Friends from "../../components/Home/Friends/Friends";
import Gallery from "../../components/Home/Gallery/Gallery";
import Footer from "../../components/Footer/Footer";

const Home = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const scrollToGallery = () => {
      const gallerySection = document.querySelector(".Gallery-Component");

      window.scrollTo({
        top: gallerySection.offsetTop,
        behavior: "smooth",
      });
    };

    if (window.location?.href?.includes("#Gallery-Component"))
      scrollToGallery();
    else scrollToTop();
  }, []);

  return (
    <>
      <Navbar />
      <SlideShow />
      <Pillers />
      <SubPiller />
      <Friends />
      <Gallery />
      <Footer />
    </>
  );
};

export default Home;
