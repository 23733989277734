import React from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home.js";
import AboutUs from "./pages/About Us/AboutUs.js";
import ProgramCenter from "./pages/ProgramCenter/ProgramCenter.js";
import HiddenPage from "./pages/shop/Shop.js";
import Communtity from "./pages/CommuntiyCenter/Community.js";
import Education from "./pages/Pillers/Education/Education.js";
import Farming from "./pages/Pillers/Farming/Farming.js";
import Construction from "./pages/Pillers/Construction/Construction.js";
import Athletic from "./pages/Pillers/Athletic/Athletic.js";
import Healing from "./pages/Pillers/Healing/Healing.js";
import Scientfic from "./pages/Pillers/Scientific/Scientific.js";
import Spiritual from "./pages/Pillers/Spiritual/Spiritual.js";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/ProgramCenter" element={<ProgramCenter />} />
          <Route path="/CommuntityCenter" element={<Communtity />} />
          <Route path="/Shop" element={<HiddenPage />} />
          <Route path="/Farming Research & Farm Hub" element={<Farming />} />
          <Route
            path="/Hawaii-Oceania Education & Cultural Center"
            element={<Education />}
          />
          <Route
            path="/Scientific Academy For Innovative Learning & Leadership"
            element={<Scientfic />}
          />
          <Route
            path="/Hawaii Elite Athletic Training Center"
            element={<Athletic />}
          />
          <Route
            path="/Integrative Health & Healing Center"
            element={<Healing />}
          />
          <Route
            path="/Hawaii Spiritual Center & Center for Inter-Faith Relationships"
            element={<Spiritual />}
          />
          <Route path="/KF Construction" element={<Construction />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
