import React from "react";
import "./Team.css";

const TeamMember = ({ name, linkedin, designation }) => (
  <div className="Nara-Div">
    <a style={{ textDecoration: "none" }} target="_blank" href={linkedin}>
      <h6 className="Team-Names-Linkedin">{name}</h6>
    </a>
    <p className="Designation">{designation}</p>
  </div>
);

const Team = () => {
  return (
    <div className="Aboutus">
      <div className="Aboutus-Prime1">
        <div className="team">
          <div style={{ paddingLeft: "2vw" }} className="team-Prime">
            <p className="team-text">Who We Are</p>
            <h2 className="team-heading">Our Team</h2>
            <p className="aboutUs-details">
              Every member of our board of directors is a thought leader who has
              made significant contributions to our society. Each one brings a
              unique set of skills and expertise to our organization.
            </p>
          </div>
          <div className="team-Prime1">
            <TeamMember
              name="Nara Sihavong"
              linkedin="https://www.linkedin.com/in/narasihavong/"
              designation="Founder, Permanent Trustee and President"
            />
            <TeamMember
              name="Rev. Philip G. Harmon"
              linkedin="https://www.linkedin.com/in/philip-harmon-6b7b096a/"
              designation="Secretary and Vice President"
            />
            <TeamMember
              name="Alan J. L. Chang"
              linkedin="https://www.linkedin.com/in/alan-j-l-chang/"
              designation="Vice President and Board"
            />
          </div>
        </div>
        <div className="team">
          <div className="team-Prime1">
            <TeamMember
              name="Nainoa Logan"
              linkedin="https://www.linkedin.com/in/nainoa-logan-58653015/"
              designation="Advisor"
            />
            <TeamMember
              name="Ramsay Taum"
              linkedin="https://www.linkedin.com/in/ramsaytaum/"
              designation="Advisor"
            />
            <TeamMember
              name="Reid Mizue"
              linkedin="https://www.linkedin.com/in/reid-mizue-7524b238/"
              designation="Advisor"
            />
          </div>
          <div className="team-Prime1">
            <TeamMember
              name="Kumu Larry De Rego"
              linkedin="https://www.linkedin.com/in/larry-derego-9a54673b/"
              designation="Advisor"
            />
            <TeamMember name="David Furuto" designation="Advisor" />
            <TeamMember name="Dr. Michael Tom" designation="Advisor" />
          </div>
        </div>
        <div className="team">
          <div className="team-Prime1">
            <TeamMember
              name="Dr. Riley Kitamura"
              linkedin="https://www.linkedin.com/in/riley-kitamura-943b0b70/"
              designation="Advisor"
            />
            <TeamMember name="Kena Heffernan" designation="Advisor" />
            <TeamMember
              name="Florian Sydow"
              linkedin="https://www.linkedin.com/in/florian-sydow-6b692770/"
              designation="Advisor"
            />
          </div>
          <div className="team-Prime1">
            <TeamMember
              name="Jon Kiko"
              linkedin="https://www.linkedin.com/in/kiko-john-a08b5763/"
              designation="Advisor"
            />
            <TeamMember
              name="Rambeau Gahan"
              linkedin="https://www.linkedin.com/in/rambeau-gahan-3820b374/"
              designation="Advisor"
            />
            <TeamMember
              name="Scott Schmitke J.D."
              linkedin="https://www.linkedin.com/in/scott-schmidtke-333b3ba8/"
              designation="Advisor"
            />
          </div>
        </div>
        <div className="team">
          <div className="team-Prime1">
            <TeamMember
              name="Dawson Sihavong"
              linkedin="https://www.linkedin.com/in/dawsonsihavong/"
              designation="Advisor"
            />
            <TeamMember name="" designation="" />
            <TeamMember name="" linkedin="" designation="" />
          </div>
          <div className="team-Prime1"></div>
        </div>
      </div>
    </div>
  );
};

export default Team;
