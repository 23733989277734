import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Map.css";
import Map3 from "../../assets/Map3.png";
import Map4 from "../../assets/Map4.png";
import Map5 from "../../assets/Map5.png";
const divStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "70vh",
};
const slideImages = [
  {
    url: Map4,
  },
  {
    url: Map3,
  },
  {
    url: Map5,
  },
];
const Map = () => {
  return (
    <div className="Maps-Prime">
      <a
        className="land"
        href="https://eastmanpublic.s3.amazonaws.com/land+Sale.pdf"
        target="blank"
      >
        Land Details
      </a>
      <h1>Near Landmarks - Location</h1>
      <div className="slide-containerMap">
        <Slide indicators={true}>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              ></div>
            </div>
          ))}
        </Slide>
      </div>
    </div>
  );
};

export default Map;
