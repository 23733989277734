import React, { useState } from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
// import "./SlideShow.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import images from "./SlideShowImages";

const Healing = () => {
  const [autoPlay, setAutoPlay] = useState(false);
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };
  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z" />
        </svg>
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          fill="#fff"
        >
          <path d="M512 256L270 42.6v138.2H0v150.6h270v138z" />
        </svg>
      </button>
    ),
  };

  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "70vh",
  };
  const slideImages = [
    {
      url: images["Health1.png"],
      caption: (
        <div className="SideShowx">
          <h1 className="Heading">Integrative Health & Healing Center</h1>
        </div>
      ),
    },
    {
      url: images["Health2.png"],
      caption: (
        <div className="SideShowx">
          <h1 className="Heading">Integrative Health & Healing Center</h1>
        </div>
      ),
    },
    {
      url: images["Health3.png"],
      caption: (
        <div className="SideShowx">
          <h1 className="Heading">Integrative Health & Healing Center</h1>
        </div>
      ),
    },
    {
      url: images["Health4.png"],
      caption: (
        <div className="SideShowx">
          <h1 className="Heading">Integrative Health & Healing Center</h1>
        </div>
      ),
    },
    {
      url: images["Health5.png"],
      caption: (
        <div className="SideShowx">
          <h1 className="Heading">Integrative Health & Healing Center</h1>
        </div>
      ),
    },
  ];

  return (
    <>
      <Navbar />
      <div className="slide-container">
        <Slide
          autoplay={autoPlay}
          prevArrow={properties.prevArrow}
          nextArrow={properties.nextArrow}
        >
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              >
                <span>{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
      <Footer />
    </>
  );
};

export default Healing;
