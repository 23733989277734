import React from "react";
import "./SubPiller.css";
import Center from "../../../assets/Center.png";
import { Link } from "react-router-dom";

const SubPiller = () => {
  return (
    <div className="SubPiller">
      {/* <Link to="/ProgramCenter" className="Sub-nav">
        Program Center
      </Link> */}
      <div>
        <img
          src={Center}
          style={{ filter: "brightness(20.5)", width: "100px" }}
        />
      </div>
      <a
        href="https://eastmanpublic.s3.amazonaws.com/Kaluanui+Education+Program.pdf"
        className="Sub-nav"
        target="_blank"
      >
        Community Programs
      </a>
    </div>
  );
};

export default SubPiller;
