import React, { useEffect } from "react";
import "./ProgramCenter.css";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import Cards from "../../components/ProgramCenter/Cards/Cards";

const ProgramCenter = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };
    scrollToTop();
  }, []);
  return (
    <>
      <Navbar />
      <div className="AboutUs">
        <div className="AboutUs-Banner">
          <h1 className="AboutUs-heading">Program Center</h1>
        </div>
      </div>
      <Cards />
      <Footer />
    </>
  );
};

export default ProgramCenter;
