import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import "./Scientific .css";
import SAIL from "../../../assets/PagesImages/SAIL1.png";
import SAIL2 from "../../../assets/PagesImages/SAIL3.png";
import SAIL3 from "../../../assets/PagesImages/SAIL2.png";

const Scientific = () => {
  const divStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    height: "50vh",
  };
  const slideImages = [
    {
      url: SAIL,
      caption: "Scientific Academy for Innovative Learning and Leadership",
    },
    {
      url: SAIL2,
      caption: "Scientific Academy for Innovative Learning and Leadership",
    },
    {
      url: SAIL3,
      caption: "Scientific Academy for Innovative Learning and Leadership",
    },
  ];

  return (
    <>
      <Navbar backgroundColor="#000" />
      <div className="slide-container Slidertop">
        <Slide>
          {slideImages.map((slideImage, index) => (
            <div key={index}>
              <div
                style={{
                  ...divStyle,
                  backgroundImage: `url(${slideImage.url})`,
                }}
              >
                <span className="page-Name">{slideImage.caption}</span>
              </div>
            </div>
          ))}
        </Slide>
      </div>
      <div className="SAIL">
        <div className="SAIL-Prime">
          <div className="Picture-Div">
            <div className="Picture"></div>
            <div className="Details">
              <h2 className="Details-heading">
                Scientific Academy for Innovative
                <br /> Learning and Leadership
              </h2>
              <p className="pedd-text">
                A mentorship template for innovative and integrated scientific
                learning in Agriculture, Aquaponics and Ocean Farming. We are
                truly at a unique time in history. There is no doubt thats the
                environment and economy are hurting, both at local and global
                levels. Yet, there exists an abundance of solutions, and people
                seeking to spread them. Providing gathering places and training
                grounds for great minds and hearts to come together to share
                their knowledge, passion, and insights is key to creating a
                prosperous future for all.
              </p>
            </div>
            <div style={{ display: "flex" }}>
              <div className="detail-Image"></div>
              <div className="detail-Image-Two"></div>
            </div>
            <div className="detail-Image-Three"></div>
            <div className="Details">
              <p className="pedd-text">
                Creating a new platform for progressive education in renewable
                technologies, media production, health and wellness, and
                cultural heritage, the Academy will provide much-needed
                facilities to foster the new innovations that will help solve
                some of the great economic and environmental challenges of
                today. The Academy will be established at the Ko’olauloa
                Foundation offering a specialized set of courses and experiences
                with local indigenous wisdom blended into the curriculum.
              </p>
            </div>
          </div>
          <div className="Details">
            <p className="pedd-text">
              SAIL empowers future leaders through innovative education,
              <br />
              encouraging a dynamic approach to learning and leadership
              <br />
              development.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Scientific;
