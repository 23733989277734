import React from "react";
import Navbar from "../../../components/Navbar/Navbar";
import Footer from "../../../components/Footer/Footer";

const Farming = () => {
  return (
    <>
      {" "}
      <Navbar />
      <h1 style={{ marginTop: "30vh", fontSize: "10rem" }}>Coming Soon</h1>
      <p
        style={{
          textAlign: "center",
          fontFamily: "fantasy",
          fontSize: "5rem",
          marginBottom: "15vh",
        }}
      >
        Farming
      </p>
      <Footer />
    </>
  );
};

export default Farming;
