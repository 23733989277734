import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../../assets/Logo.png";
import { Turn as Hamburger } from "hamburger-react";
import { Link, useNavigate } from "react-router-dom";

const Nav = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleNav = () => {
    setOpen(!isOpen);
  };

  const scrollToGallery = (e) => {
    const gallerySection = document.querySelector(".Gallery-Component");

    if (gallerySection)
      window.scrollTo({
        top: gallerySection.offsetTop,
        behavior: "smooth",
      });
  };

  return (
    <div
      className={`Navbar ${isOpen ? "overflow-visible" : "overflow-hidden"}`}
    >
      <div className="Nav-One">
        <div>
          <Link to="/" className="Nav-Links">
            <img
              className="Logo"
              style={{ width: "60px" }}
              src={Logo}
              alt="KO'OLAULOA FOUNDATION"
            />
          </Link>
        </div>
      </div>
      <div className={isOpen ? "Nav-Two open" : "Nav-Two"}>
        <Link to="/" className="Nav-Links">
          Home
        </Link>
        <Link
          to="/#Gallery-Component"
          onClick={scrollToGallery}
          className="Nav-Links"
        >
          Gallery
        </Link>
        <Link to="/AboutUs" className="Nav-Links">
          About Us
        </Link>
      </div>
      <div className="Nav-Three">
        <Hamburger color={"#fff"} toggled={isOpen} toggle={toggleNav} />
      </div>
    </div>
  );
};

export default Nav;
