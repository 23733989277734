import React from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Footer.css";
import { BiLogoFacebook } from "react-icons/bi";
import PinInput from "react-pin-input";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const password = "PONO";

  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  function handleShow() {
    setShow(true);
  }

  const onChange = (value) => {
    setValue(value);
    if (password === value) {
      setShow(false);
      window.location.replace("/Shop");
    }
  };

  return (
    <div
      style={{
        background: "#1F242E",
        width: "100%",
      }}
    >
      <div
        style={{
          height: "80%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="footerComp">
          <div className="Working-Hours">
            <h3 className="Headings"> Working Hours</h3>
            <p style={{ color: "#D0D1D3" }}> Mon-Fri: 9 AM – 6 PM</p>
            <p style={{ color: "#D0D1D3" }}>Saturday: 9 AM – 4 PM</p>
            <p style={{ color: "#D0D1D3" }}> Sunday: Closed</p>
            <a
              style={{ color: "#D0D1D3", cursor: "pointer" }}
              target="blank"
              href="https://docs.google.com/presentation/d/1vI_DMDh7umN-SAU9M4QXVsl_Mf4Dlro3/edit#slide=id.p19"
            >
              Directions to Program Site
            </a>
          </div>
          <div className="office">
            <h3 className="Headings">Office</h3>
            <p style={{ color: "#D0D1D3" }}>
              53-100 Puhuli Street
              <br />
              PO Box 433, Hauula HI 96717
            </p>
            <ul style={{ color: "#D0D1D3" }}>
              <li>
                <a className="Email" href="mailto:nara@koolauloafoundation.org">
                  nara@koolauloafoundation.org
                </a>
              </li>
              <li>
                <h3 className="Headings">
                  <a className="" href="tel:+18004419036,,101">
                    +1-800-441-9036 ext. 101
                  </a>
                </h3>
              </li>
            </ul>
          </div>
          <div className="Links">
            <h3 className="Headings">Links</h3>
            <ul style={{ color: "#D0D1D3" }}>
              <li>
                {location.pathname === "/" ? (
                  <Link
                    className="link"
                    to="/"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Home
                  </Link>
                ) : (
                  <Link className="link" to="/">
                    Home
                  </Link>
                )}
              </li>

              <li>
                {location.pathname === "/AboutUs" ? (
                  <Link
                    className="link"
                    to="/AboutUs"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    About Us
                  </Link>
                ) : (
                  <Link className="link" to="/AboutUs">
                    About Us
                  </Link>
                )}
              </li>
              <li>
                <a href="/"> News </a>
              </li>
            </ul>
          </div>
          <div className="social-Media">
            <h3 className="Headings">Get in Touch</h3>
            <div
              style={{
                display: "flex",
                width: "70%",
              }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=61554967323956"
                target="blank"
                aria-label="Visit Koolauloa Foundation on Facebook"
              >
                <div className="pageslinksDiv">
                  <BiLogoFacebook size={25} />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          height: "20%",
        }}
      >
        <div
          style={{
            width: "80%",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
            color: "#fff",
            padding: " 20px 0px",
          }}
        >
          <p style={{ margin: "0" }}>
            <a
              className="Branding"
              href="https://www.eastmanenterprises.com/"
              target="blank"
            >
              Eastman Inovations
            </a>
            © 2023. All rights reserved.
          </p>
          <Link to="/ProgramCenter" className="Hidden-button">
            Program Center
          </Link>
          {window.location.pathname !== "/shop" && (
            <Button className="me-2 mb-2" onClick={() => handleShow()}>
              Lot-6 Details
            </Button>
          )}
          <Modal
            show={show}
            fullscreen={fullscreen}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <div>
                <p className="screenLock">Screen Lock is currently on.</p>
              </div>
              <div>
                {value.length === 4 && value !== password ? (
                  <p className="Worng-PIN">
                    Whoops, wrong PIN. Please try again.
                  </p>
                ) : (
                  <p className="Access">Enter Pin to Access this Page</p>
                )}
              </div>
              <div
                style={{
                  width: "100vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ display: "flex" }}>
                  <PinInput
                    length={4}
                    type="text"
                    focus
                    secret
                    onChange={onChange}
                  />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Footer;
